$tablet: 'screen and(max-width: 1199px)';
$tablet-above: 'screen and(min-width: 1200px)';
$smallScreen: 'screen and(max-width: 1600px)';
$mobile: 'screen and(max-width: 991px)';
$mobile-above: 'screen and(min-width: 992px)';
$retina: "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)";

$base: #161616;
$yellow: #ffd800;
$orange: #FF5000;
