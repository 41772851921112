.button {
	height: 40px;
	border: none;
	outline: none;
	font-size: 18px;
	font-weight: 400;
	padding: 0 15px;
	text-decoration: none;
	user-select: none;
	transition: 300ms ease-out;

	&.style_grey {
		color: $yellow;
		background: $base;

		&:hover {
			color: $base;
			background: $yellow;

			svg {
				fill: $base; } } }

	&.style_dark {
		color: #fff;
		background: $base;

		&:hover {
			color: $yellow; } }

	&.size_middle {
		height: 70px;
		font-size: 16px;
		padding: 0 40px; }

	&__text {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center; }

	svg {
		fill: $yellow;
		margin-left: 10px;
		transition: 300ms ease-out; } }

a.button {
	display: inline-block; }

input::-webkit-contacts-auto-fill-button {
	visibility: hidden;
	pointer-events: none;
	position: absolute;
	right: 0; }

input::-ms-clear {
	width: 0;
	height: 0; }

.view-toggle {
	width: 80px;
	height: 80px;
	cursor: pointer;
	color: $yellow;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(255, 255, 255, .03);
	border-radius: 50%;
	transition: 300ms ease-out;

	&:hover {
		background: rgba(255, 255, 255, .3); }

	&.is_tile_view {
		.icon-tile {
			display: block; }

		.icon-list {
			display: none; } }

	.icon-tile {
		display: none; }

	svg {
		fill: currentColor; }

	@media #{$mobile} {
		display: none; } }

.fancybox-is-open .fancybox-bg {
	opacity: .99; }

.fancybox-bg {
	background: rgba(0, 0, 0, .99); }

.fancybox-toolbar,
.fancybox-navigation {
	opacity: 1 !important; }

.fancybox-button {
	background: rgba(255, 255, 255, .1);

	&--close {
		width: 110px;
		height: 110px;
		border-radius: 50%;
		top: 50px;
		right: 50px;

		&:before {
			content: '';
			width: 30px;
			height: 30px;
			background: url('../images/close.svg') no-repeat center center;
			background-size: 100%;
			@include abscenter; }

		svg {
			display: none; }

		@media #{$mobile} {
			top: 0;
			right: 0; } }

	&--arrow_left,
	&--arrow_right {
		width: 60px !important;
		height: 60px !important;
		padding: 0 !important;
		border-radius: 50%;

		div {
			display: none; }

		&:before {
			content: '';
			width: 16px;
			height: 16px;
			@include abscenter; } }

	&--arrow_left {
		left: 70px !important;

		@media #{$mobile} {
			left: 0 !important; }

		&:before {
			background: url('../images/prev.svg') no-repeat center center;
			background-size: 16px 16px; } }

	&--arrow_right {
		right: 70px !important;

		@media #{$mobile} {
			right: 0 !important; }

		&:before {
			background: url('../images/next.svg') no-repeat center center;
			background-size: 16px 16px; } } }
