.is-menu-open {
	.menu {
		opacity: 1;
		pointer-events: all; }

	.menu-nav__elem,
	.menu-logos,
	.menu-main,
	.menu-bottom,
	.menu-lang {
		opacity: 1;
		transform: translate3d(0, 0, 0);
		transition: .6s transform cubic-bezier(.165,.64,.44,1), .3s opacity ease-out; }

	.menu-lang {
		transition-delay: .4s; }

	.menu-logos {
		transition-delay: 1s; }

	.menu-main {
		transition-delay: 1.1s; }

	.menu-bottom {
		transition-delay: 1.2s; }

	.burger {
		&:hover {
			background-color: #f1f1f1; } }

	.burger__line {
		&:nth-child(1) {
			transform: rotate(45deg) translate3d(5px, 5px, 0); }

		&:nth-child(2) {
			opacity: 0;
			transform: scale(.1) rotate(45deg); }

		&:nth-child(3) {
			transform: rotate(135deg) translate3d(-5px, 5px, 0); } } }

.menu {
	background: $yellow;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 11;
	color: $base;
	opacity: 0;
	pointer-events: none;
	transition: 300ms ease-out;

	@media #{$mobile-above} {
		display: flex;
		align-items: center; }

	@media #{$mobile} {
		overflow: auto; } }

.menu-container {
	max-width: 1700px;
	width: 100%;
	margin: 0 auto;

	@media #{$mobile-above} {
		display: flex;
		align-items: center;
		padding: 0 200px 0 150px;

		@media #{$tablet} {
			padding: 0 60px; } }

	@media #{$mobile} {
		padding: 115px 30px 60px; } }

.menu-left,
.menu-right {
	@media #{$mobile-above} {
		width: 50%; } }

.menu-left {
	@media #{$mobile} {
		margin-bottom: 68px; } }

.menu-right {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex-direction: column; }

.menu-lang {
	position: absolute;
	top: 40px;
	right: 140px;
	opacity: 0;
	transform: translate3d(-60px, 0, 0);

	@media #{$mobile} {
		top: 55px;
		right: 110px; } }

.menu-nav {
	&__elem {
		opacity: 0;
		transform: translate3d(-60px, 0, 0);

		@for $i from 1 through 5 {
			&:nth-child(#{$i}) {
				transition-delay: $i * .1s + .5s; } }

		@media #{$mobile-above} {
			&:not(:last-child) {
				margin-bottom: 30px; } }

		@media #{$mobile} {
			&:not(:last-child) {
				margin-bottom: 23px; } } }

	&__link {
		font-weight: 300;
		font-size: 70px;
		line-height: 1;
		text-transform: uppercase;

		@media #{$tablet-above} {
			transition: 300ms ease-out;

			&:hover {
				opacity: .4; } }

		@media #{$mobile} {
			font-size: 50px; }

		@media (max-width: 360px) {
			font-size: 45px; } } }

.menu-logos {
	opacity: 0;
	transform: translate3d(-60px, 0, 0);

	@media #{$mobile-above} {
		display: flex;
		align-items: center;
		margin: 0 0 90px;

		&__image {
			&:not(:last-child) {
				margin-right: 15px; } } }

	@media #{$mobile} {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin: 0 0 41px;

		&__image {
			&:first-child {
				width: 168px; }

			&:nth-child(2) {
				width: 148px; }

			img {
				width: 100%; } } } }

.menu-main {
	opacity: 0;
	transform: translate3d(-60px, 0, 0);

	@media #{$mobile-above} {
		margin: 0 0 90px;
		text-align: right; }

	@media #{$mobile} {
		width: 100%;
		margin: 0 0 40px; } }

.menu-subnav {
	margin: 0 0 20px;

	@media #{$mobile} {
		margin: 0 0 24px; }

	&__link {
		font-size: 21px;
		line-height: 1;
		text-transform: uppercase;
		border-bottom: 1px solid currentColor;
		transition: 300ms ease-out;

		&:hover {
			border-color: transparent; } } }

.menu-info {
	font-size: 18px;
	line-height: 30px;

	a {
		margin-right: 23px;
		border-bottom: 1px solid currentColor;
		transition: 300ms ease-out;

		&:hover {
			border-color: transparent; } }

	@media #{$mobile} {
		font-size: 16px;
		line-height: 25px; } }

.menu-bottom {
	display: flex;
	align-items: center;
	opacity: 0;
	transform: translate3d(-60px, 0, 0);

	@media #{$mobile} {
		width: 100%;
		justify-content: space-between; } }

.menu-mail {
	@media #{$mobile-above} {
		margin-right: 40px;

		.button.style_grey {
			&:hover {
				color: $yellow;
				background-color: rgba(0, 0, 0, .4);

				svg {
					fill: $yellow; } } } }

	@media #{$mobile} {
		.button {
			font-size: 16px; } } }

.menu-social {
	display: flex;
	align-items: center;

	@media #{$mobile-above} {
		&__elem {
			&:not(:last-child) {
				margin-right: 45px; } } }

	@media #{$mobile} {
		&__elem {
			&:not(:last-child) {
				margin-right: 15px; } }

		&__link {
			svg {
				transform: scale(.8); } } }

	&__link {
		display: block;

		.icon-vimeo {
			width: 29px;
			height: 24px; }

		.icon-fb {
			width: 17px;
			height: 30px; }

		.icon-in {
			width: 27px;
			height: 25px; } } }
