.filter-tags {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end; }

.filter-tag {
	padding: 9px 10px;
	display: flex;
	align-items: center;
	margin: 0 0 12px 12px;
	background: rgba(255, 255, 255, 0.15);
	transition: 300ms ease-out;

	&:hover {
		background: rgba(255, 255, 255, .3); }

	&.is_active {
		color: $base;
		background: $yellow;

		.filter-tag__control {
			&:before {
				display: none; }

			.icon-cross {
				display: block; } } }

	&__title {
		font-size: 14px;
		line-height: 1;
		text-transform: uppercase;
		margin-right: 10px; }

	&__control {
		&:before {
			content: '';
			display: block;
			width: 8px;
			height: 8px;
			background: #fff;
			border-radius: 50%; }

		.icon-cross {
			fill: $base;
			display: none; } } }

.bx-filter-parameters-box {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;

	.checkbox {
		display: block;
		margin: 0 0 12px 12px; } }

.bx-filter-input-checkbox {
	position: relative;
	cursor: pointer;
	display: block;
	user-select: none;

	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		opacity: 0;

		&:checked + {
			.bx-filter-param-text {
				color: $base;
				background: $yellow;

				&:before {
					width: 12px;
					height: 12px;
					right: 8px;
					background: url('../images/cross-dark.svg') no-repeat;
					background-size: 100%; } } } } }

.bx-filter-param-text {
	padding: 9px 30px 9px 10px;
	display: flex;
	align-items: center;
	background: rgba(255, 255, 255, 0.15);
	transition: 300ms ease-out;
	font-size: 14px;
	line-height: 1;
	text-transform: uppercase;
	position: relative;
	transition: .3s ease-out;

	&:hover {
		background: rgba(255, 255, 255, .3); }

	&:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 10px;
		margin: auto;
		display: block;
		width: 8px;
		height: 8px;
		background: #fff;
		border-radius: 50%; } }
