.is-burger-hide {
	.burger {
		display: none; } }

.header {
	height: 160px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 0 60px 0 70px;
	z-index: 10;
	background: linear-gradient(0deg, rgba(12, 12, 12, 0) 0%, #070707 100%);
	transition: .6s transform cubic-bezier(.165,.64,.44,1);

	@media #{$tablet} {
		height: 120px; }

	@media #{$mobile} {
		padding: 0 30px; } }

.header-container {
	color: #fff;
	height: inherit;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media #{$mobile-above} {
		padding-right: 120px; } }

.header-logo {
	opacity: 0;
	display: flex;
	align-items: center;
	transition: .6s transform cubic-bezier(.165,.64,.44,1), .3s opacity ease-out;
	transform: translate3d(0, -60px, 0); }

.logo {
	display: flex;
	align-items: center;
	will-change: opacity;
	transition: 300ms ease-out;

	.logoSvgClass1,
	.logoSvgClass2 {
		transition: 300ms ease-out; }

	&:hover {
		.logoSvgClass2 {
			fill: $yellow; } }

	svg {
		width: 167px;
		display: block;

		&:not(:last-child) {
			margin-right: 10px; } }

	@media #{$mobile} {
		svg {
			max-width: 110px;

			&:not(:last-child) {
				margin-right: 5px; } } }

	@media (max-width: 370px) {
		svg {
			max-width: 90px; } } }

.header-info {
	display: flex;
	align-items: center;
	text-transform: uppercase; }

.header-nav {
	font-size: 14px;
	line-height: 1;
	display: flex;

	&:not(:last-child) {
			margin-right: 30px; }

	&__elem {
		opacity: 0;
		transform: translate3d(0, -60px, 0);
		transition: .6s transform cubic-bezier(.165,.64,.44,1), .3s opacity ease-out;

		&:not(:last-child) {
			margin-right: 30px; }

		@for $i from 1 through 3 {
			&:nth-child(#{$i}) {
				transition-delay: $i * .1s + .5s; } } }

	&__link {
		transition: 300ms ease-out;

		&:hover {
			color: $yellow; } }

	@media #{$mobile} {
		display: none; } }

.header-lang {
	font-size: 14px;
	line-height: 1;
	opacity: 0;
	transform: translate3d(0, -60px, 0);
	transition: .6s transform cubic-bezier(.165,.64,.44,1), .3s opacity ease-out;
	transition-delay: .8s;

	@media #{$mobile} {
		display: none; }

	&__link {
		transition: 300ms ease-out;

		&:hover {
			color: $yellow; } } }

.burger {
	width: 60px;
	height: 60px;
	position: fixed;
	top: 50px;
	right: 60px;
	z-index: 12;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: #fff;
	border-radius: 50%;
	opacity: 0;
	transform: translate3d(0, -60px, 0);
	transition: .3s background ease-out, .6s 1s transform cubic-bezier(.165,.64,.44,1), .3s 1s opacity ease-out;

	@media #{$mobile-above} {
		&:hover {
			background-color: $yellow; } }

	&__line {
		width: 26px;
		height: 1px;
		background: $base;
		margin: 3px 0;
		transition: 300ms ease-out; }

	@media #{$tablet} {
		top: 30px; }

	@media #{$mobile} {
		right: 30px; } }
