.is-light-theme {
	background-image: linear-gradient(180deg, #E4F2F6, #E4F2F6);
	background-position: 0px 0px, 50% 50%;

	.header {
		background: linear-gradient(0deg, rgba(238, 238, 238, 0) 0%, #E4F2F6 100%); }

	.logo {
		.logoSvgClass1 {
			fill: #fff; }

		.logoSvgClass2 {
			fill: $orange; }

		&:hover {
			.logoSvgClass2 {
				fill: $yellow; } } }

	.header-container,
	.page-wrapper {
		color: #161616; }

	.footer {
		color: #161616;
		background: linear-gradient(0deg, #E4F2F6 0%, rgba(238, 238, 238, 0) 100%); }

	.footer-copy {
		text-shadow: none; }

	.page-scroll-up {
		background-color: $orange;

		&:hover {
			box-shadow: 0 0 0 10px $orange; } }

	.page-round__elem {
		border: none;
		background: $yellow;
		opacity: 1; }

	.footer-social__link,
	.view-toggle {
		color: #fff;
		background: $orange;

		svg {
			fill: #fff; } }

	.footer-social__link {
		&:hover {
			background-color: $yellow;

			svg {
				fill: #161616; } } }

	.section-title {
		color: #161616; }

	.detail-button {
		color: #fff;
		background-color: rgba(0, 0, 0, .15);

		&:hover {
			background-color: rgba(0, 0, 0, .2); } }

	.filter-tag,
	.bx-filter-param-text {
		color: #fff;
		background: $orange; }

	.bx-filter-input-checkbox input:checked + .bx-filter-param-text,
	.filter-tag.is_active {
		background: $yellow;
		color: #161616; }

	.card-box {
		color: #fff; }

	.page-scroll {
		.mCSB_scrollTools {
			z-index: 1;

			.mCSB_draggerRail {
				background: rgba(0, 0, 0, .3); }

			.mCSB_dragger {
				.mCSB_dragger_bar {
					background: $orange; } } } }

	.detail-header {
		color: #fff; }

	.detail-header__returnline {
		position: absolute;
		width: 100px;
		height: 40px;
		left: -90px;
		top: 40px;
		background: $orange; }

	.detail-header__overline {
		position: absolute;
		width: 100px;
		height: 250px;
		left: -90px;
		top: 0;
		bottom: 20px;
		margin: auto;
		background: $orange; }

	.detail-header__logoline {
		position: absolute;
		width: 100px;
		height: 110px;
		left: -90px;
		bottom: 0;
		bottom: 20px;
		margin: auto;
		background: $orange; }

	.pager-prev,
	.pager-next {
		color: #161616;

		&:hover {
			color: $orange; } }

	.pager-separator svg {
		stroke: #161616; }

	.detail-partners {
		border-top-color: rgba(0, 0, 0, .1); }

	.detail-partners__icon {
		background-color: rgba(0, 0, 0, .3); }

	@media #{$tablet} {
		.detail-header__overline {
			display: none; }

		.detail-header {
			&:before {
				display: none; } } }

	@media #{$mobile-above} {
		.card_tile_view {
			.card-box {
				color: #161616;
				background: rgba(0, 0, 0, .1); }
			.card-box__tag {
				color: #161616; } } }

	@media #{$mobile} {
		.pager {
			border-color: $orange; }

		.pager-prev,
		.pager-next {
			color: #fff;
			background: #161616; } } }
