.detail {
	width: 100%;

	@media #{$mobile-above} {
		padding-top: 30px;
		padding-left: 90px;
		padding-bottom: 60px;

		@media #{$tablet} {
			width: calc(100% + 240px);
			padding-left: 0;
			margin: 0 -150px 0 -90px; } } }

.detail-header {
	position: relative;

	@media #{$mobile-above} {
		height: calc(100vh - 250px);
		min-height: 500px; }

	@media #{$mobile} {
		height: 310px;

		@media (min-width: 768px) {
			height: 480px; } } }

.detail-image {
	height: calc(100vh - 250px);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;

	&__cover {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		@include backcover;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover; } }

	@media #{$mobile-above} {
		min-height: 500px;

		@media #{$tablet} {
			left: 0;
			right: 0; } }

	@media #{$mobile} {
		height: 310px;
		left: -30px;
		right: -30px;
		top: 0;
		bottom: auto;
		position: absolute;

		@media (min-width: 768px) {
			height: 480px; } } }

.detail-title {
	font-weight: 500;
	font-size: 100px;
	line-height: 1.1;
	text-transform: uppercase;
	position: absolute;

	@media #{$mobile-above} {
		top: 0;
		bottom: 10px;
		left: -70px;
		right: 120px;
		display: flex;
		align-items: center;

		@media (max-width: 1840px) {
			&.is_small_size {
				font-size: 70px; } }

		@media (max-width: 1440px) {
			font-size: 70px;

			&.is_small_size {
				font-size: 50px; } }

		@media (min-width: 1841px) {
			&.is_small_size {
				font-size: 80px; } }

		@media #{$tablet} {
			left: 60px;
			font-size: 50px; } }

	@media #{$mobile} {
		left: 0;
		bottom: 60px;
		font-size: 40px;

		&.is_small_size {
			font-size: 26px; } } }

.detail-tag {
	position: absolute;
	bottom: 0;
	font-size: 18px;
	text-transform: uppercase;
	letter-spacing: 0.5em;

	@media #{$mobile-above} {
		right: 0;
		width: calc(100vh - 250px);
		height: 20px;
		text-align: center;
		transform-origin: bottom right;
		transform: rotate(90deg) translate3d(0, 90px, 0);

		@media #{$tablet} {
			font-size: 14px;
			padding-right: 20px; } }

	@media #{$mobile} {
		bottom: 30px;
		left: 0;
		font-size: 13px;
		letter-spacing: 0.3em; } }

.detail-return {
	position: absolute;
	top: 50px;
	left: -70px;
	font-size: 16px;
	z-index: 2;

	&:hover {
		color: $yellow; }

	.icon-return {
		margin-right: 16px;
		fill: currentColor; }

	&__link {
		display: flex;
		align-items: center; }

	@media #{$mobile-above} {
		@media #{$tablet} {
			left: 60px; } }

	@media #{$mobile} {
		left: 0;

		span {
			display: none; } } }

.detail-logo {
	position: absolute;
	bottom: 40px;
	left: -70px;

	@media #{$tablet} {
		left: 60px; }

	@media #{$mobile} {
		display: none; }

	img {
		max-width: 160px;
		max-height: 100px;

		&:not(:last-child) {
			margin-right: 15px; } } }

.detail-close {
	width: 60px;
	height: 60px;
	position: absolute;
	top: 65px;
	right: -30px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	background: $yellow;
	border-radius: 50%;

	.icon-cross {
		width: 18px;
		height: 18px; } }

.detail-main {
	position: relative;
	font-size: 16px;
	line-height: 30px;
	margin-bottom: 60px;
	z-index: 2;

	@media #{$mobile-above} {
		margin-right: -50px;
		margin-top: -60px;
		display: flex;

		&__left,
		&__right {
			width: 50%;
			padding: 80px; }

		&__left {
			padding-left: 0;

			&:first-child {
				margin-top: 60px; } }

		@media #{$tablet} {
			margin-right: 0;

			&__left,
			&__right {
				padding: 60px; } } }

	@media #{$mobile} {
		margin: 0 -30px 50px;

		&__left,
		&__right {
			padding: 50px 30px; } }

	&__left,
	&__right {
		display: flex;
		align-items: center; }

	&__right {
		color: #000;
		background: #fff;

		&.theme_orange {
			color: #fff;
			background: #FF5000; } }

	h1,
	&__title {
		font-size: 25px;
		line-height: 35px;
		font-weight: 700;
		margin: 0 0 30px; }

	p {
		&:not(:last-child) {
			margin-bottom: 30px; } } }

.detail-media {
	position: relative;
	z-index: 2;
	margin-bottom: 60px;

	@media #{$mobile-above} {
		display: flex; }

	&__poster {
		@media #{$mobile-above} {
			width: 50%; }

		@media #{$mobile} {
			margin: 0 -30px; }

		img {
			width: 100%;
			display: block; } }

	&__controls {
		display: flex;
		align-items: center;
		justify-content: center;

		@media #{$mobile-above} {
			width: 50%;
			padding-left: 80px;
			padding-bottom: 60px; }

		@media #{$mobile} {
			height: 260px; } } }

.detail-buttons {
	width: 100%; }

.detail-button {
	width: 100%;
	max-width: 350px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(255, 255, 255, .1);
	transition: 300ms ease-out;

	&:hover {
		background: rgba(255, 255, 255, .3); }

	&:not(:last-child) {
		margin-bottom: 20px; }

	&__title {
		width: 200px;
		text-align: center;
		font-size: 21px;
		margin-right: 35px; } }

.detail-partners {
	padding: 100px 0 0;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	border-top: 1px solid rgba(255, 255, 255, .5);

	&__icon {
		padding: 20px;
		display: flex;
		align-items: center;
		margin: 0 50px 100px 0;

		img {
			max-width: 160px;
			max-height: 100px;
 } }			// margin: 0 100px 100px 0

	@media #{$mobile-above} {
		@media #{$tablet} {
			padding-left: 60px; } }

	@media #{$mobile} {
		padding: 75px 0;

		&__icon {
			padding: 20px 10px;
			margin: 0 10px 10px 0; }

		img {
			max-width: 120px;
 } } }			// margin: 0 40px 45px 0

.detail-desc {
	background: #fff;
	padding: 80px;
	position: relative;
	z-index: 2;

	&:not(:last-child) {
		margin-bottom: 60px; }

	&__text {
		font-size: 16px;
		line-height: 30px;
		color: $base; }

	&__button {
		margin-top: 30px; }

	@media #{$mobile-above} {
		@media #{$tablet} {
			padding: 60px; } }

	@media #{$mobile} {
		padding: 50px 30px;
		margin: 0 -30px; } }

[data-text-full] {
	display: none; }

.detail-announces {
	&:not(:last-child) {
		margin-bottom: 60px; }

	@media #{$mobile-above} {
		@media #{$tablet} {
			padding-right: 60px; } } }

.detail-announce-slider {
	position: relative;
	z-index: 2;

	&:not(:last-child) {
		margin-bottom: 90px; }

	&.theme_orange {
		.detail-announce__text b,
		.detail-announce__counter {
			color: #FF5000; }

		.detail-announce__slider:before,
		.detail-announce__prev,
		.detail-announce__next {
			background: #FF5000; } }

	@media #{$mobile} {
		width: calc(100% + 60px);
		margin: 0 -30px;

		.swiper-slide {
			padding: 0 30px; } } }

.detail-announce {
	position: relative;
	z-index: 2;

	@media #{$mobile-above} {
		display: flex;

		&:not(:last-child) {
			margin-bottom: 90px; } }

	@media #{$mobile} {
		display: flex;
		flex-direction: column-reverse;

		&:not(:last-child) {
			margin-bottom: 70px; } }

	&.theme_orange {
		.detail-announce__text b,
		.detail-announce__counter {
			color: #FF5000; }

		.detail-announce__slider:before,
		.detail-announce__prev,
		.detail-announce__next {
			background: #FF5000; } }

	&__preview {
		position: relative;
		padding-bottom: 29%;

		@media #{$mobile-above} {
			width: 50%; }

		@media #{$mobile} {
			margin: 0 -30px;
			padding-bottom: 67%; }

		iframe {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0; } }

	&__info {
		display: flex;
		align-items: center;
		position: relative;

		@media #{$mobile-above} {
			width: 50%;
			padding-left: 80px; }

		@media #{$mobile} {
			margin-bottom: 40px; } }

	&__slider {
		width: 100%;
		height: 100%;
		padding-top: 80px;

		@media #{$mobile-above} {
			&:before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 80px;
				right: 0;
				height: 1px;
				background: #fff; } }

		.swiper-slide {
			padding-bottom: 20px; } }

	&__top {
		position: absolute;
		left: calc(50% + 80px);
		top: 0;
		right: 0;
		z-index: 3;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 40px;

		@media #{$mobile} {
			left: 30px;
			right: 30px; } }

	&__counter {
		min-width: 90px;
		font-size: 25px;
		line-height: 30px; }

	&__nav {
		display: flex; }

	&__prev,
	&__next {
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		outline: none;
		background: rgba(255, 255, 255, .15);
		border-radius: 50%;

		&:not(:last-child) {
			margin-right: 35px; }

		&.swiper-button-disabled {
			opacity: .3; }

		svg {
			width: 10px;
			height: 20px;
			fill: none;
			stroke: #fff;
			stroke-width: 8px; } }

	&__text {
		font-size: 16px;
		line-height: 30px; } }
