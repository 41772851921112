.is-light-theme {
	.prealoder {
		background: #E4F2F6;
	}

	.lds-ripple div {
		border-color: #FF5000
	}
}

.is-page-loaded {
	.prealoder {
		opacity: 0;
	}
}

.prealoder {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(9, 10, 10, 1);
	transition: 300ms ease-out;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
