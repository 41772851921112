.footer {
	height: 140px;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
	color: #fff;
	overflow: hidden;
	background: linear-gradient(0deg, #070707 0%, rgba(12, 12, 12, 0) 100%);

	@media #{$tablet} {
		height: 100px; } }

.footer-container {
	height: inherit;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 60px 0 70px;
	transition: .6s transform cubic-bezier(.165,.64,.44,1);

	@media #{$mobile} {
		padding: 0 30px; } }

.footer-copy {
	font-size: 14px;
	line-height: 20px;
	text-shadow: 0px 0px 5px #000000;
	opacity: 0;
	transition: .6s transform cubic-bezier(.165,.64,.44,1), .3s opacity ease-out;
	transform: translate3d(0, 40px, 0);

	@media #{$mobile} {
		max-width: 140px; } }

.footer-social {
	display: flex;

	&__elem {
		opacity: 0;
		transform: translate3d(0, 40px, 0);
		transition: .6s transform cubic-bezier(.165,.64,.44,1), .3s opacity ease-out;

		&:not(:last-child) {
			margin-right: 30px; }

		@for $i from 1 through 3 {
			&:nth-child(#{$i}) {
				transition-delay: $i * .1s + .5s; } } }

	&__link {
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $base;
		border-radius: 50%;
		transition: 300ms ease-out;

		&:hover {
			background-color: $yellow;

			svg {
				fill: #000; } }

		svg {
			fill: #fff;
			transition: 300ms ease-out; } }

	@media #{$mobile} {
		&__elem {
			&:not(:last-child) {
				margin-right: 5px; } } } }
