html,
body {
    height: 100% !important; }

body {
    position: relative;
    font-size: 14px;
    font-family: 'GothamPro', sans-serif;
    background-image: linear-gradient(180deg, rgba(9, 10, 10, 0.97), rgba(9, 10, 10, 0.97));
    background-position: 0px 0px, 50% 50%;
    -webkit-font-smoothing: antialiased;

    @media #{$tablet-above} {
        overflow: hidden; } }

*, :before, :after {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }

svg {
    pointer-events: none; }

input,
textarea,
button {
    border: none;
    outline: none;
    border-radius: 0; }

input[type="text"],
input[type="password"],
input[type="tel"],
input[type="email"],
input[type="number"],
textarea,
button {
    -webkit-appearance: none; }

fieldset {
    border: none; }

h1, h2, h3, h4, h5, h6 {
    font-family: inherit;
    font-weight: normal;
    color: inherit; }

a {
    color: inherit;
    text-decoration: none;
    outline: none;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none; } }

li {
    list-style: none; }

.visually-hidden {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important; }

.hidden-xs {
    @media #{$mobile} {
        display: none !important; } }

.visible-xs {
    display: none !important;

    @media #{$mobile} {
        display: block !important; } }

.container {
    width: 990px;
    margin: 0 auto;
    padding: 0 15px; }

p {
    font-size: 16px;
    line-height: 25px;

    @media #{$mobile} {
        font-size: 14px;
        line-height: 20px; } }
