[data-aos] {
	body[data-aos-easing='new-easing'] &,
	&[data-aos][data-aos-easing='ease-easing'] {
		transition-timing-function: cubic-bezier(0.48, 0, 0.12, 1); } }


[data-aos='fade-up'] {
    transform: translate3d(0, 100px, 0); }

[data-aos='fade-up-xs'] {
	transform: translate3d(0, 20px, 0); }

[data-aos='fade-up-md'] {
	transform: translate3d(0, 70px, 0); }

[data-aos='fade-left'] {
	transform: translate3d(60px, 0, 0); }

[data-aos='fade-right'] {
	transform: translate3d(-100px, 0, 0); }

[data-aos='custom-zoom-out'] {
	transform: scale(1.2);
	transition: .6s ease-out;

	&.aos-init.aos-animate {
		transform: scale(1); } }

[data-aos='scale'] {
	transform: scale(.01);
	transition: .6s ease-out;

	&.aos-init.aos-animate {
		transform: scale(1); } }
