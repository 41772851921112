@font-face {
	font-family: 'GothamPro';
	src: url('../fonts/GothamPro.eot');
	src: local('Gotham Pro'), local('GothamPro'),
		url('../fonts/GothamPro.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GothamPro.woff') format('woff'),
		url('../fonts/GothamPro.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'GothamPro';
	src: url('../fonts/GothamPro-Light.eot');
	src: local('Gotham Pro Light'), local('GothamPro-Light'),
		url('../fonts/GothamPro-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GothamPro-Light.woff') format('woff'),
		url('../fonts/GothamPro-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'GothamPro';
	src: url('../fonts/GothamPro-Medium.eot');
	src: local('Gotham Pro Medium'), local('GothamPro-Medium'),
		url('../fonts/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GothamPro-Medium.woff') format('woff'),
		url('../fonts/GothamPro-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'GothamPro';
	src: url('../fonts/GothamPro-Bold.eot');
	src: local('GothamPro-Bold'), local('GothamPro-Bold'),
		url('../fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GothamPro-Bold.woff') format('woff'),
		url('../fonts/GothamPro-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
