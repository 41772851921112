.is_main_nav_hover {
	// .main-photos__slider
	// 	opacity: 0
	// 	transition: 0s

	@media #{$tablet-above} {
		.main-photos,
		.main-photos__slider {
			transform: scale(1.3); } } }

.main-container {
	min-height: 100vh;
	display: flex;
	align-items: center; }

.main-nav {
	position: relative;
	z-index: 2;

	@media #{$mobile-above} {
		margin-left: 100px; }

	@media #{$mobile} {
		width: 100%; }

	&__elem {
		opacity: 0;
		transform: translate3d(-70px, 0, 0);
		transition: 1s transform cubic-bezier(.165,.64,.44,1), .3s opacity ease-out;

		&:not(:last-child) {
			margin-bottom: 20px; }

		@for $i from 1 through 5 {
			&:nth-child(#{$i}) {
				transition-delay: $i * .1s + .8s; } } }

	&__link {
		font-weight: 300;
		font-size: 60px;
		line-height: 1;
		display: block;
		text-transform: uppercase;
		transition: 300ms ease-out;

		&:hover {
			color: $yellow; }

		@media #{$mobile} {
			font-size: 40px; } } }

.main-photos {
	width: 35vw;
	height: 35vw;
	position: fixed;
	top: 0;
	bottom: 0;
	left: calc(50% + 50px);
	margin: auto;
	background: rgba(255, 255, 255, .05);
	border-radius: 50%;
	opacity: 0;
	transition: .3s 1.5s opacity ease-out, 1.5s transform ease-out;

	@media #{$tablet} {
		transform: scale(1) !important; }

	@media #{$mobile} {
		width: 80vw;
		height: 80vw;
		top: auto;
		left: auto;
		right: 20px;
		bottom: 100px; }

	&__slider,
	&__list {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		@media #{$mobile} {
			opacity: .6; } }

	&__list {
		z-index: 2; }

	&__slider {
		transition: .3s .2s ease-out;

		.swiper-container {
			width: 100%;
			height: 100%; }

		.swiper-slide {
			background: rgba(9, 10, 10, 1);
			border-radius: 50%; }

		.main-photo {
			opacity: .3; } } }

.main-photo {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include backcover;
	opacity: 0;
	overflow: hidden;
	border-radius: 50%;
	transition: 300ms ease-out;

	@media #{$tablet} {
		transform: translate3d(0, 0, 0) !important; }

	&.is_active {
		opacity: .3; }

	video {
		width: 100%;
		height: 100%;
		object-fit: cover; } }
