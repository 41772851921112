.page_section_team {
	@media #{$mobile-above} {
		width: calc(100% - 320px);
		margin-top: calc(50vh - 260px);

		@media #{$tablet} {
			width: calc(100% - 250px); } }

	@media #{$mobile} {
		@media (min-width: 768px) {
			margin-top: 30px; } } }

.team-list {
	display: flex;
	flex-wrap: wrap;

	@media #{$mobile-above} {
		@media #{$tablet} {
			margin-right: -30px; } }

	@media #{$mobile} {
		width: 280px;
		margin: 0 auto;

		@media (min-width: 768px) {
			width: 500px; } } }
.team-col {
	width: 280px;
	margin: 0 0 80px 0;

	@media #{$mobile} {
		width: 200px;
		margin: 0 auto 80px; } }

.team-tile {
	@media #{$mobile-above} {
		padding-right: 50px;

		&:hover {
			.team-tile__cover {
				transform: scale(1.15); } } }

	&__avatar {
		width: 200px;
		height: 200px;
		filter: grayscale(100%);
		border-radius: 50%;
		@include backcover;
		overflow: hidden;
		margin: 0 0 30px; }

	&__cover {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		@include backcover;
		transition: .5s ease-out; }

	&__info {
		font-size: 16px;
		line-height: 25px; }

	&__name {
		color: $yellow;
		margin: 0 0 5px; } }
