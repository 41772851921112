.page-background {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0; }

.page-round {
	position: fixed;
	z-index: 1;
	width: 100%;
	height: 100%;

	&__elem {
		width: 500px;
		height: 500px;
		margin-top: -20vh;
		margin-left: 79vw;
		border-style: dashed;
		border-width: 1px;
		border-color: #4d4d4d;
		border-radius: 500px;
		opacity: 0.4;

		&.round_2 {
			width: 300px;
			height: 300px;
			margin-top: 45vh;
			margin-left: 55vw; }

		&.round_3 {
			width: 150px;
			height: 150px;
			margin-top: -110vh;
			margin-left: 40vw; } } }

.page-scroll {
	height: 100%;

	.mCSB_inside > .mCSB_container {
		margin-right: 0; }

	.mCSB_scrollTools {
		top: 200px;
		bottom: 200px;
		right: 82px;

		.mCSB_draggerRail {
			width: 1px;
			background: rgba(255, 255, 255, .3); }

		.mCSB_dragger {
			.mCSB_dragger_bar {
				width: 3px;
				background: $yellow;
				border-radius: 0; } } } }

.page-wrapper {
	height: 100%;
	position: relative;
	z-index: 2;
	color: #fff; }

.page-container {
	@media #{$mobile-above} {
		display: flex;
		padding: 100px 150px 100px 90px; }

	@media #{$mobile} {
		display: flex;
		flex-direction: column;
		padding: 160px 30px 60px; } }

.page-section {
	@media #{$mobile-above} {
		width: calc(100% - 350px);

		@media #{$tablet} {
			width: calc(100% - 250px); } }

	@media (min-width: 1500px) {
		width: calc(100% - 400px); } }

.page-aside {
	@media #{$mobile-above} {
		width: 350px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: fixed;
		top: 0;
		bottom: 0;
		right: 150px;
		right: 140px;

		@media #{$tablet} {
			right: 60px; } }

	@media (min-width: 1500px) {
		width: 400px; }

	@media #{$mobile} {
		order: -1;
		margin-bottom: 40px; } }

.page-cards {
	@media #{$mobile-above} {
		padding-top: 40px;
		padding-right: 50px;

		@media #{$tablet} {
			padding-right: 0; } } }

.page-preloader-bottom {
	height: 100px;
	position: relative;
	background: rgba(255, 255, 255, .1);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 50px;

	@media #{$mobile-above} {
		margin-left: -90px; }

	@media #{$mobile} {
		height: 60px;
		margin-top: 20px;
		margin-bottom: 50px; } }

.page-scroll-up {
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: $yellow;
	border-radius: 50%;
	position: fixed;
	top: 200px;
	right: 75px;
	cursor: pointer;
	z-index: 8;
	opacity: 0;
	pointer-events: none;
	transition: .3s ease-out;

	&.is_visible {
		opacity: 1;
		pointer-events: all; }

	&:hover {
		box-shadow: 0 0 0 10px $yellow; }

	svg {
		fill: none; }

	@media #{$tablet} {
		display: none; } }
