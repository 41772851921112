.is_card_view_change {
	.page-cards {
		opacity: 0;
		transform: translate3d(-80px, 0, 0); } }

.page-cards {
	transition: .6s ease-out; }

.card-list {
	padding-bottom: 20px; }

.card-box {
	display: block;
	position: relative;

	@media #{$tablet-above} {
		&:hover {
			.card-box__image {
				&:before {
					opacity: .3; } } } }

	&:hover {
		.card-box__title {
			color: $yellow; } }

	&__image {
		position: relative;
		@include backcover;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #000;
			opacity: 0;
			z-index: 1;
			transition: .3s ease-out; }

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover; } }

	&__title {
		text-transform: uppercase;
		transition: 300ms color ease-out; }

	&__desc {
		max-width: 520px;
		font-size: 16px;
		line-height: 25px;

		@media (max-width: 1500px) {
			font-size: 14px;
			line-height: 20px; }

		img {
			max-width: 160px;
			max-height: 100px; } }

	&__tag {
		color: #fff;
		font-size: 13px;
		text-transform: uppercase; }

	&__logo {
		img {
			max-width: 160px;
			max-height: 100px; } } }

@media #{$mobile} {
	.card-list {
		margin: 0 -30px 20px; }

	.card-box {
		&:not(:last-child) {
			margin-bottom: 40px; } }

	.card-box__image {
		position: relative;
		height: 260px;

		@media (min-width: 768px) {
			height: 400px; } }

	.card-box__header {
		position: absolute;
		top: 30px;
		left: 30px;
		right: 30px;
		z-index: 2; }

	.card-box__title {
		font-weight: 500;
		font-size: 30px;
		line-height: 1;

		&.is_adaptive_font {
			font-size: 24px;
			line-height: 1.2; }

		&.is_adaptive_font_small {
			font-size: 20px;
			line-height: 1.3; } }

	.card-box__desc {
		max-width: 520px;
		position: absolute;
		bottom: 30px;
		left: 30px;
		right: 80px;
		font-size: 12px; }

	.card-box__logo {
		margin: 0 0 10px; }

	.card-box__text {
		display: none; }

	.card-box__tag {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 260px;
		height: 20px;
		opacity: .7;
		font-size: 11px;
		padding-right: 20px;
		letter-spacing: 0.5em;
		text-align: right;
		transform-origin: bottom right;
		transform: rotate(90deg) translate3d(0, 50px, 0); } }

@media #{$mobile-above} {
	.card_list_view {
		margin-left: -90px;

		.card-box {
			width: 100%;
			height: calc(100vh - 250px);
			min-height: 470px;

			&:not(:last-child) {
				margin-bottom: 100px; } }

		.card-box__image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%; }

		.card-box__header {
			position: absolute;
			top: 80px;
			left: 80px;
			right: 80px;
			z-index: 2;

			@media #{$tablet} {
				top: 50px;
				left: 40px; } }

		.card-box__title {
			font-weight: 500;
			font-size: 100px;
			line-height: 1;

			@media (min-width: 1501px) {
				&.is_adaptive_font {
					font-size: 70px;
					line-height: 1.2; }

				&.is_adaptive_font_small {
					font-size: 55px;
					line-height: 1.3; } }

			@media (max-width: 1500px) {
				font-size: 50px;

				&.is_adaptive_font {
					font-size: 40px;
					line-height: 1.2; }

				&.is_adaptive_font_small {
					font-size: 35px;
					line-height: 1.3; } } }

		.card-box__desc {
			max-width: 520px;
			position: absolute;
			bottom: 70px;
			left: 80px;
			right: 70px;

			@media #{$tablet} {
				bottom: 50px;
				left: 40px; } }

		.card-box__logo {
			margin: 0 0 10px; }

		.card-box__text {
			margin-bottom: 30px; }

		.card-box__tag {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 300px;
			height: 20px;
			opacity: .7;
			padding-right: 70px;
			letter-spacing: 0.5em;
			text-align: right;
			transform-origin: bottom right;
			transform: rotate(90deg) translate3d(0, 90px, 0);

			@media #{$tablet} {
				padding-right: 50px;
				transform: rotate(90deg) translate3d(0, 60px, 0); } } }

	.card_tile_view {
		display: flex;
		flex-wrap: wrap;
		margin-left: -20px;
		margin-right: -40px;

		@media #{$smallScreen} {
			margin-left: -30px;
			margin-right: -15px; }

		.card-box {
			width: 350px;
			background: rgba(255, 255, 255, .05);
			margin: 0 60px 60px 0;

			@media #{$smallScreen} {
				// width: 250px
				width: calc(33.333% - 30px);
				margin: 0 15px 30px; }

			@media (max-width: 1360px) {
				width: calc(50% - 60px);
				margin: 0 30px 60px; } }

		.card-box__image {
			position: relative;
			height: 260px;

			@media #{$smallScreen} {
				height: 160px; } }

		.card-box__header {
			height: 140px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 0 30px;

			@media #{$smallScreen} {
				height: 130px; } }

		.card-box__title {
			font-weight: 500;
			font-size: 40px;
			line-height: 40px;

			&.is_adaptive_font {
				font-size: 30px;
				line-height: 1.2; }

			&.is_adaptive_font_small {
				font-size: 20px;
				line-height: 1.3; }

			@media #{$smallScreen} {
				font-size: 25px;
				line-height: 30px;

				&.is_adaptive_font {
					font-size: 20px;
					line-height: 1.2; }

				&.is_adaptive_font_small {
					font-size: 16px;
					line-height: 1.3; } } }

		.card-box__logo,
		.card-box__text {
			display: none; }

		.card-box__desc {
			@media #{$smallScreen} {
				font-size: 12px;
				line-height: 15px; } }

		.card-box__info {
			padding: 30px;
			text-align: center;

			@media #{$smallScreen} {
				padding: 20px; } }

		.card-box__tag {
			opacity: .5;
			letter-spacing: 0.3em;
			margin: 0 0 10px;

			@media #{$smallScreen} {
				font-size: 12px;
				line-height: 15px; } } } }
