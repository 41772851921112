.icon-vimeo {
	width: 18px;
	height: 15px; }

.icon-fb {
	width: 10px;
	height: 18px; }

.icon-in {
	width: 16px;
	height: 15px; }

.icon-mail {
	width: 20px;
	height: 16px;

	@media #{$mobile} {
		width: 16px;
		height: 12px; } }

.icon-list {
	width: 25px;
	height: 25px; }

.icon-tile {
	width: 26px;
	height: 26px; }

.icon-cross {
	width: 12px;
	height: 12px; }

.icon-return {
	width: 40px;
	height: 15px; }

.icon-gallery {
	width: 20px;
	height: 20px; }

.icon-play {
	width: 24px;
	height: 24px; }

.icon-arrow-prev,
.icon-arrow-next {
	width: 45px;
	height: 90px; }

.icon-separator {
	width: 90px;
	height: 90px; }

.icon-up {
	width: 12px;
	height: 6px; }

.icon-download {
	width: 20px;
	height: 18px; }

.icon-posters {
	width: 20px;
	height: 26px; }
