.section-box {
	text-align: right;

	@media #{$mobile-above} {
		min-height: 280px; } }

.section-head {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin: 0 0 40px;

	.section-title {
		margin: 0; }

	.view-toggle {
		margin-left: 25px; } }

.section-title {
	font-weight: 300;
	font-size: 50px;
	line-height: 1;
	color: $yellow;
	text-transform: uppercase;
	margin: 0 0 40px;

	@media #{$tablet-above} {
		@media (max-width: 1500px) {
			font-size: 45px; } }

	@media #{$mobile} {
		font-size: 40px;
		margin: 0 0 15px; } }

.section-desc {
	margin: 0 0 50px;

	@media #{$mobile-above} {
		@media (max-height: 860px) {
			&.is_small_size {
				p {
					font-size: 13px;
					line-height: 18px; } } } }

	@media #{$mobile} {
		margin: 0 0 20px; } }
