.pager {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	z-index: 2;
	margin-top: 190px;

	@media #{$mobile-above} {
		@media #{$tablet} {
			margin-top: 140px;
			padding-left: 60px;
			padding-right: 60px; } }

	@media #{$mobile} {
		border-top: 1px solid #fff;
		margin-top: 80px;
		padding-top: 60px;
		margin-bottom: 60px; }

	svg {
		fill: none; } }

.pager-separator {
	svg {
		stroke: #fff; }

	@media #{$mobile} {
		svg {
			height: 60px; } } }

.pager-prev,
.pager-next {
	width: calc(50% - 50px);
	color: #fff;
	display: flex;
	align-items: center;
	transition: 300ms ease-out;

	&.disabled {
		pointer-events: none;
		opacity: 0.1; }

	&:hover {
		color: $yellow; }

	svg {
		stroke: currentColor; }

	&__title {
		width: calc(100% - 90px);
		font-size: 25px;
		line-height: 30px;

		&.is_small_size {
			font-size: 15px;
			line-height: 18px; } } }

.pager-prev {
	justify-content: flex-start;

	@media #{$mobile} {
		width: 40px;
		height: 40px;
		justify-content: center;
		border-radius: 50%;
		background: rgba(255, 255, 255, .15);

		svg {
			width: 10px;
			height: 15px;
			display: block;
			stroke-width: 4px; } }

	&__title {
		text-align: left;
		padding-left: 70px;

		@media #{$mobile} {
			display: none; } } }

.pager-next {
	justify-content: flex-end;

	@media #{$mobile} {
		width: 40px;
		height: 40px;
		justify-content: center;
		border-radius: 50%;
		background: rgba(255, 255, 255, .15);

		svg {
			width: 10px;
			height: 15px;
			display: block;
			stroke-width: 4px; } }

	&__title {
		text-align: right;
		padding-right: 70px;

		@media #{$mobile} {
			display: none; } } }
