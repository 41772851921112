.contact-map {
	@include backcover;

	@media #{$mobile-above} {
		position: fixed;
		top: 150px;
		bottom: 150px;
		left: 0;
		right: 0; }

	@media #{$mobile} {
		height: 350px;
		margin: 40px -25px; } }

.contact-desc {
	font-size: 21px;
	line-height: 30px;

	@media #{$mobile-above} {
		margin-top: 80px; }

	@media #{$mobile} {
		margin-top: 40px; }

	p {
		font-size: 21px;
		line-height: 30px;
		margin: 0 0 30px; } }

.contact-info {
	a {
		color: $yellow; } }
