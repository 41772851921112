.is-page-loaded {
	// header
	.header-logo,
	.header-nav__elem,
	.header-lang,
	.burger {
		opacity: 1;
		transform: translate3d(0, 0, 0); }

	// footer
	.footer-copy,
	.footer-social__elem {
		opacity: 1;
		transform: translate3d(0, 0, 0); }

	// main
	.main-nav__elem {
		opacity: 1;
		transform: translate3d(0, 0, 0); }
	.main-photos {
		opacity: 1; }

	// scrollbar
	.mCSB_draggerContainer {
		opacity: 1; } }

.mCSB_draggerContainer {
	opacity: 0;
	transition: .3s 1s ease-out; }


.is-hide-header-footer {
	.header {
		transform: translate3d(0, -160px, 0); }
	.footer {
		background: transparent; }
	.footer-container {
		transform: translate3d(0, 140px, 0); } }
